import { useState } from "react";
import { FILE_TYPES } from "../utils/constants";

const Form = () => {
  // Define the form data
  const [form, setForm] = useState({
    type: "",
    file: "",
  });

  // Define the response data
  const [response, setResponse] = useState();

  // Define the loading state
  const [loading, setLoading] = useState(false);

  // handle form change
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  // handle file upload
  const handleFileUpload = (e) => {
    setForm({ ...form, file: e.target.files[0] });
    console.log(form.file);
  };

  // handle form submit
  const handleOnSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    setResponse();

    var formdata = new FormData();
    formdata.append("image", form.file);
    formdata.append("type", "CI_RO");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BACKEND_URL}/ocr`, requestOptions)
      .then((res) => res.text())
      .then((result) => {
        // console.log(JSON.parse(result)["status"]);
        // console.log(Object.values(JSON.parse(result)));
        const data = Object.values(JSON.parse(result));
        // console.log("status " + data[1]);

        if (!JSON.parse(result)["status"]) {
          setLoading(false);

          alert("Error, please try again");

          return;
        }
        const data_data = data[0];
        // transform data_data to array of objects
        const data_data_array = Object.entries(data_data);
        console.log(Object.values(JSON.parse(result)));

        setResponse({
          status: "success",
          data: Object.values(JSON.parse(result)),
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setResponse({
          status: "error",
          data: error,
        });
        setLoading(false);
      });

    console.log("Form Submitted");
  };

  return (
    <div class="relative min-h-screen md:flex gap-4 items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 bg-gray-500 bg-no-repeat bg-cover relative items-center">
      <div class="hidden md:block absolute bg-black opacity-60 inset-0 z-0"></div>

      {/* Form */}
      <div class="mb-5 sm:max-w-lg w-full p-10 bg-white rounded-xl z-10">
        <div class="text-center">
          <h2 class="mt-5 text-3xl font-bold text-gray-900">
            Data extraction DEMO
          </h2>
          {/* <p class="mt-2 text-sm text-gray-400">
            Lorem ipsum is placeholder text.
          </p> */}
        </div>
        <form class="mt-8 space-y-3" onSubmit={handleOnSubmit}>
          <div class="grid grid-cols-1 space-y-2">
            <label class="text-sm font-bold text-gray-500 tracking-wide">
              File type
            </label>
            <select
              class="text-base p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
              name="type"
              id="type"
              value={form.type}
              onChange={handleChange}
            >
              {FILE_TYPES.map((item, index) => {
                return (
                  <option value={item} key={index}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
          <div class="grid grid-cols-1 space-y-2">
            <label class="text-sm font-bold text-gray-500 tracking-wide">
              Attach Document
            </label>
            <div class="md:flex items-center justify-center w-full">
              <label class="flex flex-col rounded-lg border-4 border-dashed w-full h-60 p-10 group text-center">
                <div class="h-full w-full text-center flex flex-col items-center justify-center items-center  ">
                  <div class="flex flex-auto max-h-48 w-2/5 mx-auto -mt-10"></div>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-10 h-10 text-blue-400 group-hover:text-blue-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    />
                  </svg>
                  <p class="pointer-none text-gray-500">
                    <span>Drag and drop</span> files here <br /> or{" "}
                    <span class="text-blue-600 hover:underline">
                      select a file
                    </span>{" "}
                    from your computer
                  </p>
                </div>
                <input
                  type="file"
                  class="hidden"
                  id="file"
                  name="file"
                  onChange={handleFileUpload}
                />
              </label>
              {
                // show the image preview for the selected file
                form.file && (
                  <div class="mt-4 md:mt-0 ml-2 flex flex-auto md:max-h-48 md:w-2/5 mx-auto">
                    <img
                      src={URL.createObjectURL(form.file)}
                      alt="preview"
                      class="w-full h-full object-cover"
                    />
                  </div>
                )
              }
            </div>
          </div>
          <p class="text-sm text-gray-300">
            <span>File type: PDF or JPG</span>
          </p>
          <div>
            <button
              type="submit"
              disabled={loading}
              class="my-5 w-full flex justify-center bg-blue-500 text-gray-100 p-4  rounded-md tracking-wide
                                        font-semibold  focus:outline-none focus:shadow-outline hover:bg-blue-600 shadow-lg cursor-pointer transition ease-in duration-300"
            >
              {loading ? (
                <svg
                  class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8z"
                  ></path>
                </svg>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      </div>

      {/* Values */}

      {/* {response && (
        <div class="sm:max-w-lg w-full p-10 bg-white rounded-xl z-10">
          <div class="text-center">
            <h2 class="my-5 text-3xl font-bold text-gray-900">
              Extracted values
            </h2>
            <div class="mt-2 grid grid-cols-2 gap-4">
              {
                // show responose.data as string
                response && response?.data?.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className="relative">
                        <label
                          htmlFor={item[1].id}
                          className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                        >
                          {item[1].id}
                        </label>
                        <input
                          type="text"
                          name={item[1].id}
                          id={item[1].id}
                          className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          value={item[0]}
                        />
                      </div>
                    </div>
                  );
                })
              }

            </div>
            <div>
                <button
                  type="submit"
                  class="my-5 w-full flex justify-center bg-blue-500 text-gray-100 p-4  rounded-md tracking-wide
                  font-semibold  focus:outline-none focus:shadow-outline hover:bg-blue-600 shadow-lg cursor-pointer transition ease-in duration-300"                >
                  Save
                </button>
              </div>
          </div>
        </div>
      )} */}

      {response && (
        <div class="sm:max-w-lg w-full p-10 bg-white rounded-xl z-10">
          <div class="text-center">
            <h2 class="my-5 text-3xl font-bold text-gray-900">
              Extracted values
            </h2>
            <div class="mt-2 md:grid grid-cols-2 gap-4">
              {
                // print response.data as object string
                // JSON.stringify(response.data[0])

                // iterate all response.data[0] keys
                Object.keys(response.data[0]).map((key, index) => {
                  if (key == "Domiciliu" || key == "Loc nastere") {
                    // console.log(response.data[0][key]);
                    // let genders = Object.keys(response.data[0][key]);
                    // console.log(genders);

                    // iterate all response.data[0][key] keys and return the value
                    return Object.keys(response.data[0][key]).map(
                      (item, index) => {
                        return (
                          <div key={item} className="mt-4 md:mt-0">
                            <div className="relative">
                              <label
                                htmlFor={item}
                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                              >
                                {item} ({key})
                              </label>
                              <input
                                type="text"
                                name={key}
                                id={key}
                                className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                value={response.data[0][key][item]}
                              />
                            </div>
                          </div>
                        );
                      }
                    );
                  } else {
                    return (
                      <div key={index} className="mt-4 md:mt-0">
                        <div className="relative">
                          <label
                            htmlFor={key}
                            className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                          >
                            {key}
                          </label>
                          <input
                            type="text"
                            name={key}
                            id={key}
                            className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            value={response.data[0][key]}
                          />
                        </div>
                      </div>
                    );
                  }
                })
              }
            </div>
            <div>
              <button
                type="submit"
                class="my-5 w-full flex justify-center bg-blue-500 text-gray-100 p-4  rounded-md tracking-wide
                  font-semibold  focus:outline-none focus:shadow-outline hover:bg-blue-600 shadow-lg cursor-pointer transition ease-in duration-300"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Form;
